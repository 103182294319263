html,
body {
  height: 100%;
  scroll-behavior: smooth;
  margin: 0 !important;
}

#__next {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}

.animate-pulse {
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: pulse-green 1s infinite;
  border-radius: 2.5px;
}

@keyframes pulse-green {
  0% {
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}

@media print {
  .no-print {
    visibility: hidden;
  }
  canvas {
    display: block;
    page-break-inside: avoid;
  }
  .chart {
    display: block;
    page-break-inside: avoid;
  }
  .tile {
    display: block;
    page-break-inside: avoid;
  }
  .react-grid-item {
    display: block;
    page-break-inside: avoid;
  }
  table,
  th,
  td,
  tr {
    display: block;
    page-break-inside: avoid;
  }
}

.Toastify__toast-body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.dropzone {
  display: flex;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.25rem;
  padding-bottom: 1.5rem;
  justify-content: center;
  border-radius: 0.375rem;
  border-width: 2px;
  border-color: #d1d5db;
  border-style: dashed;
  margin: 10px 0;
}

.dropzone-inner {
  display: flex;
  color: #4b5563;
  font-size: 0.875rem;
  line-height: 1.25rem;
  justify-content: center;
  align-items: center;
}
